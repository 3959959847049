<template id="rezervari">
  <div class="content">
      <el-card class="box-card" >
        <div slot="header" class="clearfix">
            <span class="no_page">Programarea dumneavoastra va fi anulata. Doriti sa continuati?</span>
        </div>
        <el-form ref="form" :model="form">
          <el-form-item label="Motivul anularii">
            <el-input v-model="form.Motiv" placeholder="(Optional)" type="textarea"></el-input>
          </el-form-item>
        </el-form>

       <el-button @click="anulare()" class="anuleaza_button">Anuleaza</el-button>

    </el-card>
  </div>
</template>

<script>
import Vue from "vue";
import settings from "@/backend/LocalSettings"

export default {
  name: "anulare",
  components: {},
  data() {
    return {
      form: {
         Motiv: "",
      },
      IdProgramare: undefined,
      Data: {},
    };
  },

  methods: {
    anulare: async function() {
        let args = {IdProgramare: this.IdProgramare, Motiv: this.form.Motiv, CheckSender:settings.get_key()}
        let api_url = "https://service.anvelopeinstoc.ro/api/index.php/test_api/anuleaza";
        let response = await this.$http.post(api_url, args);
        if (response.body.success) {
            this.$message({type: 'success', message: 'Programarea a fost anulata cu succes',  onClose: () => {
                this.$router.push('/')
            }});
            
        } else {
            this.$message({type: 'error', message: 'A intervenit o eroare in anularea programarii'});
        }
    },

    refresh_info: async function () {
      this.IdProgramare = this.$route.params.id;
      let args = { IdProgramare: this.IdProgramare, CheckSender:settings.get_key()}
      let api_url = "https://service.anvelopeinstoc.ro/api/index.php/test_api/check_client";
      let response = await this.$http.post(api_url, args);

      if (response.data.error) 
        this.$message.error({message: 'Nu exista o programare cu acest Id', onClose: () => {
            this.$router.push('/')
        }});
    },
  },
  mounted: async function () {
    this.refresh_info();
  },
};
</script>

<style lang="less" scoped>
    @gri: #F1F2F2;
    @gri_inchis: #8e97b3;
    @albastru_transparent:rgba(76, 100, 232, 0.445);
    @albastru: #4C63E8;
    @verde: #4C63E8;
    @rosu-hover: #F7E8E8;
    @rosu: #ec7376;
    @rosu_deschis: #F7E8E8;
    @galben: #FEF6CB;
    @galben_wallpaper: #FCFEF1;
    @galben_inchis: #E1D588;
    @liber: #caffc4;
    @liber_text: #185210;
    @ocupat: #f3f5f9;
    @ocupat_text: #b3bbca;
    @zi_indisponibila: #f7e9e9;
    @zi_indisponibila_text: #752f2f;
.content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no_page {
  font-size: 20px;
  font-weight: 600;
  user-select: none;
  text-align: center;
  color: @albastru
}

.anuleaza_button {
    background-color: @albastru;
    color: white;
    float: right;
    margin: 16px 0;
}

  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 630px;
  }

@media only screen and (max-width: 768px) {
  .no_page {
    font-size: 14px;
  }

  .box-card {
    width: 90%;
  }
}
</style>